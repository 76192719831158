<template>
  <div>
    <div class="table-wrapper">
      <Centered>
        <div v-if="leader && leader.canReadSalesbookRecords">
          <div class="loader-container" v-if="isLoading">
            <div class="loader"/>
          </div>
          <div v-else>
            <div class="record-info">
              Poniższa tabela zawiera spotkania utworzone od 21 marca 2022.
            </div>
            <table>
              <thead>
              <tr>
                <th v-for="(field, i) in SalesbookCalendarFields" :style="{width: field.width}" :key="i">{{field.name}}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in calendarItems.filter(el => el)" :key="index">
                <td v-for="(field, i) in SalesbookCalendarFields" :key="i">
                  {{getFieldValue(field, item)}}
                </td>
              </tr>
              </tbody>
            </table>
            <div class="actions-bottom">
              <div>
                <Button @click="exportRecordsToCsv">
                  Pobierz plik .csv
                </Button>
                <div
                  v-if="showCsvExportMessage"
                  class="csv-message"
                >
                  {{exportSuccessMessage}}
                </div>
              </div>
              <div>
                <router-link
                  to="/form"
                  class="back-link"
                >
                  Wróć
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          Nie masz uprawnień do przeglądania rekordów.
        </div>
      </Centered>
    </div>
  </div>
</template>

<script>
import format from "date-fns/format"
import {mapActions, mapGetters} from 'vuex'
import Centered from '../components/common/Centered'
import {SalesbookCalendarFields} from '../data/salesbookCalendarFields'

export default {
  components: {
    Centered,
  },
  data(){
    return{
      SalesbookCalendarFields,
      showCsvExportMessage: false,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters('leaders', ['calendarItems', 'leader']),
    headers() {
      return this.SalesbookCalendarFields.map(({name}) => name)
    },
    exportSuccessMessage() {
      return `Wyeksportowano ${this.calendarItems.filter(el => el).length} rekordów.`
    },
  },
  async mounted() {
    if (!await this.hasCredentials()) {
      await this.$router.push('/')
      return
    }
    await this.loadCredentials({})
    this.isLoading = true
    await this.getCalendarItems()
    this.isLoading = false
  },
  methods: {
    ...mapActions('leaders', ['loadCredentials', 'hasCredentials', 'exportSalesbookCalendarItemsToCsv']),
    async getCalendarItems() {
      await this.$store.dispatch('leaders/fetchLeaderSalesbookCalendarItems', {
        username: this.leader?.login,
        password: this.leader?.password,
        prefix: this.leader?.emailPrefix
      })
    },
    getFieldValue(field, item) {
      if (!item || !field) return
      const {type, value} = field
      return type === 'date' ? this.formatDate(new Date(item[value])) : item[value]
    },
    formatDate(date) {
      return date ? format(new Date(date.toString()), 'yyyy-MM-dd HH:mm') : null
    },
    async exportRecordsToCsv() {
      await this.exportSalesbookCalendarItemsToCsv({
        headers: SalesbookCalendarFields.map(({name}) => name),
        records: this.calendarItems.filter(el => el).map(item => {
          const values = []
          for (const field of SalesbookCalendarFields) {
            values.push(this.getFieldValue(field, item))
          }
          return values
        }),
      })
      this.showCsvExportMessage = true
    }
  },
}
</script>

<style scoped lang="scss">
.record-info {
  font-size: 20px;
}
.status-panel {
  padding-top: 30px;
  padding-bottom: 60px;
}
.title{
  width: 300px;
  float: left;
}

.color {
  color: #87189D;
}

thead {
  position: sticky;
  top: 0;
}
th {
  padding: 4px 4px;
  text-align: center;
  background: #33058c;
  color: #f2f2f2;
  //border: 1px solid hsl(0 0% 0% / 1.4);
}

td {
  padding: 4px 4px;
  text-align: center;
  border: 1px solid hsl(0 0% 0% / 0.1);
  white-space: nowrap;
  //max-width: 95px;
  overflow: hidden;
  text-overflow: ellipsis;

  &.no-padding {
    padding: 0;
  }
}

tr {
  &:nth-child(2n) {
    background: hsl(0deg 0% 98%);
  }
  &.is-expanded {

    & td {
      white-space: normal;
      word-break: break-word;
    }

    & ::v-deep .foldable-td {
      white-space: normal;
      word-break: break-word;
    }

    //& .expa
  }
}
table {
  margin: 30px 0 20px 0;
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

button {
  background-color: #87189D;
  color: white;
  border: none;
}

button:hover {
  opacity: 0.8;
  cursor: pointer;
}

.back-link {
  font-size: 16px;
}

.message {
  font-weight: 700;
}

.message-success {
  color: green;
}

.message-error {
  color: red
}

.csv-button {
  margin-bottom: 10px;
}

.csv-message {
  font-weight: 700;
  font-size: 16px;
}

.actions-bottom {
  position: sticky;
  bottom: 20px;
  left: 20px;
  width: 280px;
  padding: 10px;
  background-color: #e8e8e8;
}
.loader {
  &-container {
    display: flex;
    justify-content: center;
  }
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid  #33058c; /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
  margin-top: 30px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
