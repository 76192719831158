export const heatPumpQuestions = [
    {
        title: 'Czy klient jest właścicielem nieruchomości, w której miałby odbyć się montaż?',
        name: 'propertyOwner',
        answers: [
            {
                title: 'Tak',
                value: 'Tak'
            },
            {
                title: 'Nie',
                value: 'Nie'
            }
        ]
    },
    {
        title: 'Czy dom/budynek został oddany do użytku?',
        name: 'houseBuilt',
        answers: [
            {
                title: 'Tak',
                value: 'Tak'
            },
            {
                title: 'Nie',
                value: 'Nie'
            }
        ]
    },
    {
        title: 'Czy dom/budynek posiada instalację hydrauliczną i elektryczną?',
        name: 'utilitiesInstalled',
        answers: [
            {
                title: 'Tak',
                value: 'Tak'
            },
            {
                title: 'Nie',
                value: 'Nie'
            }
        ]
    },
    {
        title: 'Czy klient posiada ogrzewanie podłogowe lub grzejniki nieżeliwne?',
        name: 'underfloorHeating',
        answers: [
            {
                title: 'Tak',
                value: 'Tak'
            },
            {
                title: 'Nie',
                value: 'Nie'
            }
        ]
    },
    {
        title: 'Czy powierzchnia domu/budynku nie przekracza 300m2?',
        name: 'smallProperty',
        answers: [
            {
                title: 'Tak',
                value: 'Tak'
            },
            {
                title: 'Nie',
                value: 'Nie'
            }
        ]
    },
    {
        title: 'Jakie jest aktualne źródło ogrzewania?',
        name: 'heatingSource',
        isInput: true,
    },
    {
        title: 'Czy dom/budynek został wybudowany po 1930r.?',
        name: 'builtAfter1930',
        answers: [
            {
                title: 'Tak',
                value: 'Tak'
            },
            {
                title: 'Nie',
                value: 'Nie'
            }
        ]
    },
    {
        title: 'Czy dom/budynek jest w pełni ocieplony?',
        name: 'wellInsulated',
        answers: [
            {
                title: 'Tak',
                value: 'Tak'
            },
            {
                title: 'Nie',
                value: 'Nie'
            }
        ]
    },
    {
        title: 'Czy klient będzie dostępny na spotkanie w ciągu 7 dni roboczych?',
        name: 'meetingThisWeek',
        answers: [
            {
                title: 'Tak',
                value: 'Tak'
            },
            {
                title: 'Nie',
                value: 'Nie'
            }
        ]
    }
];