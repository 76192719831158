<template>
  <div class="page-nav-wrapper">
    <RouterLink :to="prevPage">
      <span>
        <button :disabled="page === 1">
          Poprzednia
        </button>
      </span>
    </RouterLink>
    <div class="page-number">
      strona {{page}}
    </div>
    <RouterLink :to="nextPage">
      <button :disabled="!nextPageActive">
        Następna
      </button>
    </RouterLink>
    <div class="results-per-page">
      Wyników na stronie:
      <select @change="setRecordsPerPage">
        <option v-for="recordOption in recordsPerPageOptions" :key="recordOption">
          {{recordOption}}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  data() {
    return {
      recordsPerPageOptions: [15, 30, 45],
    }
  },
  props: {
    page: {
      type: Number,
      required: true,
    },
    nextPage: {
      type: [String, Object],
      required: true,
    },
    prevPage: {
      type: [String, Object],
      required: true,
    },
    nextPageActive: {
      type: Boolean,
    }
  },
  methods: {
    ...mapMutations('leaders', ['setLeadsPerPage']),
    async setRecordsPerPage(e) {
      await this.setLeadsPerPage(Number(e.target.value))
      this.$emit('lead-limit-change')
    }
  }
}
</script>

<style scoped>
.page-nav-wrapper {
  padding: 30px 0;
  display: flex;
  gap: 10px;
  align-items: center;
}
.page-number {
  font-size: 16px;
  font-weight: 500;
}
.results-per-page {
margin-left: 10px;
}
button {
  background-color: #FFF;
  color: #87189D;
  border: 1px #87189D solid;
  cursor: pointer;
}
button:hover {
  background-color: #87189D;
  color: #FFF;
}
button:disabled {
  opacity: 50%;
}
button:disabled:hover {
  background-color: #FFF;
  color: #87189D;
  cursor: default;
}
</style>
