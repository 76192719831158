<template>
  <div class="phone-error-msg">
    {{errorMessage}}
  </div>
</template>

<script>

export default {
  props: {
    phone: null,
  },
  data() {
    return {
      errorMessage: '',
    }
  },
  methods: {
    duplicateMessage() {
      return `Lead z podanym numerem telefonu już istnieje.`
    }
  },
  watch: {
    async phone(phoneNumber) {
      if (!phoneNumber || phoneNumber.length < 9) {
        this.errorMessage = ''
        return
      }
      const blacklistedRes = await this.$store.dispatch('leaders/checkNumberBlacklisted', {phoneNumber})
      const duplicatesRes = await this.$store.dispatch('leaders/checkLeadDuplicates', {phoneNumber})
      this.errorMessage = blacklistedRes.isBlacklisted ? 'Numer telefonu znajduje się na czarnej liście' : duplicatesRes.leads.length ? this.duplicateMessage() : ''

      this.$emit('phone-number-update', this.errorMessage)
    },
  },
}
</script>

<style scoped>
.phone-error-msg {
  color: #ff1a1a;
}
</style>
