<template>
  <div class="form-page">
    <Header />
    <PanelForm />
  </div>
</template>

<script>

import PanelForm from '../components/PanelForm'
import Header from "../components/common/Header";
import {mapActions} from "vuex";

export default {
  components: {
    Header,
    PanelForm,
  },
  computed: {

  },
  async mounted() {
    if (!await this.hasCredentials()) {
      await this.$router.push('/')
      return
    }
    await this.loadCredentials({})
  },
  methods: {
    ...mapActions('leaders', ['loadCredentials', 'hasCredentials']),
  },
}
</script>

<style scoped>
  .form-page {
    display: grid;
    grid-template-rows: auto 1fr;
    padding: 30px 0;
    grid-gap: 30px;
    box-sizing: border-box;
  }
</style>
