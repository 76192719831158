<template>
  <div :class="['checkbox', {'is-checked': isChecked}]" @click="updateCheckbox" :style="dynamicStyle">
    <div>
      <input ref="checkbox" @change="updateInput($event.target.checked)" type="checkbox" :id="id" :name="name" :value="value" :checked="isChecked">
      <div class="switcher">
        <div class="dot"></div>
      </div>
    </div>
    <label>
      <slot/>
    </label>
  </div>
</template>

<script>
  export default {
    model: {
      prop: 'modelValue',
      event: 'change'
    },
    props: {
      modelValue: {
        type: [String, Array, Boolean],
        default: null
      },
      value: {
        type: String,
        default: undefined
      },
      id: {
        type: String,
        default: undefined
      },
      name: {
        type: String,
        default: undefined
      },
      trueValue: {
        type: [String, Boolean],
        default: true
      },
      falseValue: {
        type: [String, Boolean],
        default: false
      },
      fontSize: {
        type: String,
        default: '14px'
      }
    },
    computed: {
      isChecked() {
        if (this.modelValue instanceof Array) {
          return this.modelValue.includes(this.value)
        }
        return this.modelValue === this.trueValue
      },
      dynamicStyle(){
        return{
          '--font-size': this.fontSize
        }
      }
    },
    methods: {
      updateInput(isChecked) {
        if (this.modelValue instanceof Array) {
          let newValue = [...this.modelValue]
          if (isChecked) {
            newValue.push(this.value)
          } else {
            newValue.splice(newValue.indexOf(this.value), 1)
          }
          this.$emit('change', newValue)
        } else {
          this.$emit('change', isChecked ? this.trueValue : this.falseValue)
        }
      },
      updateCheckbox() {
        this.$refs.checkbox.click()
      }
    }
  }
</script>

<style scoped lang="scss">
  .checkbox {
    display: flex;
    gap: 5px;
    padding: 4px 8px;
    align-items: center;
    font-size: var(--font-size);
    cursor: pointer;
    transition: all .1s ease;
    user-select: none;

    label {
      cursor: pointer;
    }

    &:hover {
      background: hsl(0deg 0% 90%);
    }

    .switcher {
      width: 12px;
      height: 12px;
      border: 2px solid rgb(0 0 0 / 20%);
      display: flex;
      align-items: center;
      justify-content: center;

      .dot {
        background: #86179d;
        width: 8px;
        height: 8px;
        opacity: 0;
        transform: scale(0);
        transition: all .1s ease;
      }
    }
    input {
      visibility: hidden;
      position: absolute;
      pointer-events: none;
    }

    &.is-checked {
      background: hsl(0deg 0% 87%);
      font-weight: 600;

      .switcher {
        .dot {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
</style>