export const KnownError = {
  NOT_FOUND: 'NOT_FOUND',
    INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
    USER_ALREADY_EXISTS: 'USER_ALREADY_EXISTS',
    MISSING_CREDENTIALS: 'MISSING_CREDENTIALS',
    INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
    COMMON_PASSWORD: 'COMMON_PASSWORD',
    INVALID_REQUEST: 'INVALID_REQUEST',
    SERVICE_IS_DOWN: 'SERVICE_IS_DOWN',
    SEND_SMS_ERROR: 'SEND_SMS_ERROR',
    IP_NOT_ON_ALLOWLIST: 'IP_NOT_ON_ALLOWLIST',
}