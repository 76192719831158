<template>
  <div class="consents">
    <div v-for="(consent, i) in consents" :key="consent">
      <Checkbox
        v-model="isAgree[i]"
        font-size="11px"
        @change="updateConsents"
      >
        {{consent}}
        <a v-if="!isLeaderAccountOffice" :href="url" target="_blank">{{url}}</a>
      </Checkbox>
    </div>
  </div>
</template>

<script>
import Checkbox from "@/components/common/Checkbox";
import {mapGetters} from "vuex";
export default {
  components: {Checkbox},
  data(){
    return{
      url: 'https://sundaypolska.pl/polityka-prywatnosci/',
      isAgree: []
    }
  },
  computed: {
    ...mapGetters('leaders', ['leader']),
    isLeaderAccountOffice() {
      return this.leader?.isAccountOffice
    },
    consents() {
      return this.isLeaderAccountOffice ?
        [
          'Oświadczam, że zgodnie z umową o współpracę pozyskałem zgodę od Odbiorcy na przekazanie jego danych osobowych spółce Sunday Polska Sp. z o.o. zwracając się do Odbiorcy z pytaniem o następującej treści: "Czy wyraża Pan/Pani zgodę na przekazanie Pan/Pani danych spółce Sunday Polska Sp. z o.o. i kontakt telefoniczny z jej przedstawicielem w celu przedstawienia Panu/Pani oferty handlowej?"'
        ]
        : [
          'Informuję, że nasza rozmowa jest rejestrowana, a administratorem danych osobowych jest Sunday Polska Sp. z o.o. Więcej informacji o przetwarzaniu danych osobowych można pozyskać z naszej strony internetowej '
        ]

    }
  },
  methods: {
    updateConsents(){
      this.$emit('change', this.isAgree.every(item => item))
    },
    clearConsents(){
      this.isAgree = Array(this.consents.length).fill(false)
    }
  },
  created() {
    this.isAgree = Array(this.consents.length).fill(false)
  }
}
</script>

<style scoped>

</style>
