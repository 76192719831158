<template>
  <div>
    <a class="logout-button" @click="logout">Wyloguj</a>
  </div>
</template>

<script>
import {mapMutations, mapGetters} from "vuex";
import UniversalCookie from "universal-cookie";

export default {
  methods: {
    ...mapMutations('leaders', ['setLeader']),
    ...mapGetters('leaders', ['leader']),
    async logout() {
      const cookies = new UniversalCookie()
      cookies.remove('login')
      cookies.remove('password')
      await this.setLeader(null)
      await this.$router.go()
    }
  },
}
</script>

<style scoped lang="scss">
.logout-button {
  color: #86179d;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
</style>
