export const headerNames = [
  {
    name: 'Zewnętrzne ID',
    width: '80px'
  },
  {
    name: 'Utworzono',
    width: '120px'
  },
  {
    name: 'Status',
    width: '120px'
  },
  {
    name: 'Imię',
    width: '80px'
  },
  {
    name: 'Nazwisko',
    width: '100px'
  },
  {
    name: 'Firma',
    width: '80px'
  },
  {
    name: 'Województwo',
    width: '100px'
  },
  {
    name: 'Powiat',
    width: '100px'
  },
  {
    name: 'Kod pocztowy',
    width: '90px'
  },
  {
    name: 'Miejscowość',
    width: '100px'
  },
  {
    name: 'Numer telefonu (1)',
    width: '90px'
  },
  {
    name: 'Numer telefonu (2)',
    width: '90px'
  },
  {
    name: 'Ulica',
    width: '90px'
  },
  {
    name: 'Nr budynku',
    width: '60px'
  },
  {
    name: 'Rachunek',
    width: '70px'
  },
  {
    name: 'Pokrycie dachu',
    width: '70px'
  },
  {
    name: 'Nachylenie dachu',
    width: '70px'
  },
  {
    name: 'Komentarz',
    width: '70px'
  },
  {
    name: 'Kampania',
    width: '70px'
  },
  {
    name: 'ID leadera',
    width: '200px'
  },
]
