<template>
  <div class="header">
    <Center>
      <div class="header-wrapper">
        <div class="left">
          <div class="logo">
            <img src="/img/sunday-logo.png" alt="Sunday Logo"/>
          </div>
          <div class="title">Moje <span class="color">leady</span></div>
        </div>
<!--        <input class="search" @input="$emit('searchChange', $event)" :value="searchValue" placeholder="Szukaj..." type="text">-->
      </div>
    </Center>
  </div>
</template>

<script>
  import Center from './Centered'
  export default {
    components: {Center},
    props: {
      searchValue: {
        type: String,
        required: true
      }
    }
  }
</script>

<style scoped lang="scss">

  .logo {
    img {
      height: 48px;
    }
  }
  .header {
    background: white;
    position: sticky;
    top: 0;
  }

  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
  }

  .search {
    border: 1px solid rgb(0 0 0 / 15%);
    padding: 6px 12px;
    font-size: 15px;
    outline: none;

    &:focus {
      border-color: rgb(0 0 0 / 25%);
    }
  }

  .color {
    color: #86179d;
    font-weight: 700;
  }
  .title {
    font-size: 18px;

  }

  .left {
    display: flex;
    align-items: center;
    gap: 20px;
  }
</style>
