<template>
  <div class = "message">
    <div class = "header"></div>
    <div class = messagetext><h3 :style="style">{{ text }}</h3></div>
  </div>
</template>

<script>
export default {
  name: "Message",
  props: {
    text: String,
    color: String,
  },
  computed:{
    style(){
      return `color: ${this.color};`
    }
  }
}
</script>

<style scoped>
  .message{
    width: 300px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    border-radius: 15px;
    min-height: 100px;
  }
  .header{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    height: 30px;
    background: rgb(51,5,141);
    background: linear-gradient(180deg, rgba(51,5,141,1) 0%, rgba(135,24,157,1) 100%);
  }
  .messagetext{
    box-sizing: border-box;
    border: 1px solid rgb(51,5,141);
  }
</style>
