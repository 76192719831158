<template>
  <div class="center-wrapper">
    <div class="center">
      <slot/>
    </div>
  </div>

</template>

<script>
  export default {

  }
</script>

<style scoped lang="scss">
  .center-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .center {
    width: calc(100% - 40px);
    max-width: 1600px;
  }
</style>
