<template>
  <div class="search-bar">
    <div :class="['input-wrapper', inputInactive ? 'input-inactive' : '']">
      <input
        :disabled="inputInactive"
        type="text"
        :value="text"
        name="items"
        autocomplete="off"
        @input="updateValue($event); openList()"
        ref="searchBarInput"
      >
      <i
        v-if="inputInactive"
        class="fas fa-edit cancel-icon"
        @click="cancel"
      ></i>
    </div>
    <div v-if="showList" class="datalist-wrapper">
      <select
        :disabled="lengthWarn"
        class="datalist-select"
        :value="text" size="6"
        @input="updateValue($event); closeList()"
        @change="() => toggleInputInactive(true)"
      >
        <option v-if="lengthWarn">Wpisz minimum 2 znaki</option>
        <option
          v-else
          v-for="item in listToShow"
          :key="item.id"
          @mouseover="() => setOptionSelected(true)"
          @mouseout="() => setOptionSelected(false)"
        >{{item.name}}</option>
      </select>
      <div @click="closeList" class="popup-close-overlay"></div>
      <button
        class="button"
        v-if="text.length >= 2 && showAddButton && !listToShow.find(element => element.name.toLowerCase() === text.toLowerCase())"
        @click="confirmAddElement"
      >{{elementName}} nie na liście</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Searchbar",
  props: {
    dataList: Array,
    text: String,
    elementName: String,
    showAddButton: Boolean,
  },
  data(){
    return{
      showList: false,
      inputInactive: false,
      optionSelected: false
    }
  },
  watch:{
    text(val){
      setTimeout(()=>{
        if(val === this.text){
          this.$emit('delay')
        }
      }, 500)
    }
  },
  mounted() {
    this.toggleInputInactive(false)
  },
  methods:{
    toggleInputInactive(val) {
      this.inputInactive = val
    },
    setOptionSelected(val) {
      this.optionSelected = val
    },
    closeList(){
      this.showList = false
      if (!this.optionSelected) this.$emit('change', '')
      this.setOptionSelected(false)
    },
    openList(){
      this.showList = true
    },
    updateValue(event){
      this.$emit('change', event.target.value)
    },
    confirmAddElement() {
      const addElement = confirm(`Czy chcesz dodać ${this.elementName}: ${this.text}`)
      if (!addElement) {
        this.focusOnInput()
        return
      }
      this.showList = false
      this.toggleInputInactive(true)
    },
    cancel() {
      this.$emit('change', '')
      this.inputInactive = false
      this.openList()
      this.focusOnInput()
    },
    focusOnInput() {
      this.$nextTick(() => this.$refs['searchBarInput'].focus())
    },
  },
  computed:{
    listToShow() {
      return this.text.length < 2 ? [] : this.dataList
    },
    lengthWarn(){
      return this.text.length < 2
    },
  }
}
</script>

<style scoped lang="scss">

.popup-close-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: -1;
}

.datalist-wrapper {
  position: absolute;
  left: 0;
  width: 200px;
  z-index: 1;
}

.datalist-select {
  width: 100%;
  outline: 0;
  box-sizing: border-box;
}

.datalist-select:disabled {
  opacity: 1;
}

.search-bar{
  margin: 5px;
  position: relative;
  display: inline-block;
}

.button {
  border: none;
  padding: 5px 8px;
  font-size: 12px;
  outline: none;
  background: #86179d;
  color: white;
  cursor: pointer;
  font-weight: 600;

  &:hover {
    background: white;
    color: #86179d;
  }
}

.cancel-icon {
  color: #86179d;;
  font-size: 22px;
  cursor: pointer;
}

.input-wrapper {
  display: flex;
  align-items: center;
  gap: 6px;
}

.input-inactive {
  font-size: 14px;
  background-color: #dedede;
  box-sizing: border-box;

  & input[type=text] {
    background-color: #dedede;
    font-weight: 600;
    color: grey;
    font-size: 14px;
  }
}

input::-webkit-calendar-picker-indicator {
  display: none !important;
}

input[type=text], input[type=number] {
  font-size: 13px;
  -webkit-appearance: none;
  text-align: left;
  padding: 6px 12px;
  outline: none;
  transition: all .1s ease;
  border: none;
  background: hsl(0deg 0% 90%);

  &:focus {
    background: hsl(0deg 0% 85%);
  }
}
</style>
