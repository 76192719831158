<template>
  <div :class="['loading', `color-${color}`]"/>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: false,
      default() {
        return 'theme'
      },
    },
  },
}
</script>

<style scoped lang="scss">

@keyframes rotate-infinite {
  0% {
    transform: rotate(0)
  }
  100% {
    transform: rotate(360deg)
  }
}

.loading {
  width: 16px;
  height: 16px;
  border: 3px solid transparent;
  border-top: 3px solid white;
  border-radius: 100px;
  animation: rotate-infinite .33s linear infinite;

  &.color-theme {
    border-top: 3px solid #7a1afd;
  }
}
</style>
