export const provinces = [
    {
        title: 'Dolnośląskie',
        value: 'dolnośląskie'
    },
    {
        title: 'Kujawsko-Pomorskie',
        value: 'kujawsko-pomorskie'
    },
    {
        title: 'Lubelskie',
        value: 'lubelskie'
    },
    {
        title: 'Lubuskie',
        value: 'lubuskie'
    },
    {
        title: 'Łódzkie',
        value: 'łódzkie'
    },
    {
        title: 'Małopolskie',
        value: 'małopolskie'
    },
    {
        title: 'Mazowieckie',
        value: 'mazowieckie'
    },
    {
        title: 'Opolskie',
        value: 'opolskie'
    },
    {
        title: 'Podkarpackie',
        value: 'podkarpackie'
    },
    {
        title: 'Podlaskie',
        value: 'podlaskie'
    },
    {
        title: 'Pomorskie',
        value: 'pomorskie'
    },
    {
        title: 'Śląskie',
        value: 'śląskie'
    },
    {
        title: 'Świętokrzyskie',
        value: 'świętokrzyskie'
    },
    {
        title: 'Warmińsko-Mazurskie',
        value: 'warmińsko-mazurskie'
    },
    {
        title: 'Wielkopolskie',
        value: 'wielkopolskie'
    },
    {
        title: 'Zachodniopomorskie',
        value: 'zachodniopomorskie'
    },
]