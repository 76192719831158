<template>
  <div>
    <TableHeader @searchChange="onSearchChange" :searchValue="searchContent"/>
    <div class="table-wrapper">
      <Centered>
        <CsvBlacklistedButton />
        <div v-if="leader && leader.canReadRecords">
          <table>
            <thead>
            <tr>
              <th v-for="(header, i) in headerNamesFiltered" :style="{width: header.width}" :key="i">{{header.name}}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(lead, index) in leadsFiltered" :key="index">
              <td v-if="displayExternalId">{{lead.externalSourceId}}</td>
              <td>{{formatDate(lead.createdAt)}}</td>
              <td>{{altarSubStatusToExternalSystemStatusMap.get(lead.externalSystemOperationalStatus)}}</td>
              <td>{{lead.firstName}}</td>
              <td>{{lead.lastName}}</td>
              <td>{{lead.companyName}}</td>
              <td>{{lead.Province.name}}</td>
              <td>{{lead.Region.name}}</td>
              <td>{{lead.zipCode}}</td>
              <td>{{lead.City.name}}</td>
              <td>{{lead.PhoneNumber.phoneNumber}}</td>
              <td>{{lead.AlternatePhoneNumber ? lead.AlternatePhoneNumber.phoneNumber : ''}}</td>
              <td>{{lead.address}}</td>
              <td>{{lead.addressStreetNumber}}</td>
              <td>{{lead.averageBill}}</td>
              <td>{{lead.roofCoverage}}</td>
              <td>{{lead.roofAngle}}</td>
              <td>{{lead.comment}}</td>
              <td>{{lead.campaign}}</td>
              <td>{{lead.leaderId}}</td>
            </tr>
            </tbody>
          </table>
          <PageNavigator
            :prev-page="prevPage"
            :next-page="nextPage"
            :page="currentPage"
            :next-page-active="nextPageActive"
            @lead-limit-change="onLeadLimitChange"
          />
          <CsvButton
            class="csv-button"
            :leads="leads"
            :displayExternalId="displayExternalId"
            @csv-submitted="csvSubmitted"
          ></CsvButton>
          <div :class="[submitSuccess ? 'message-success' : 'message-error', 'message']">
            {{message}}
          </div>
        </div>
        <div v-else>
          Nie masz uprawnień do przeglądania rekordów.
        </div>
      <router-link
        to="/form"
        class="back-link"
      >
        Wróć
      </router-link>
      </Centered>

    </div>
  </div>
</template>

<script>
import format from "date-fns/format"
import {mapActions, mapGetters} from 'vuex'
import Centered from '../components/common/Centered'
import {headerNames} from '../data/headerNames'
import {ErrorCodesMessages} from '../enums/ErrorCodesMessages'
import TableHeader from '../components/common/TableHeader'
import CsvButton from '../components/CsvButton'
import {altarSubStatusToExternalSystemStatusMap} from '../data/externalStatusMap'
import CsvBlacklistedButton from "../components/CsvBlacklistedButton";
import PageNavigator from "../components/PageNavigator";

export default {
  components: {
    PageNavigator,
    CsvBlacklistedButton,
    Centered,
    TableHeader,
    CsvButton,
  },
  data(){
    return{
      headerNames,
      searchContent: "",
      expandedContractId: null,
      showModal: false,
      userName: "",
      userPhone: null,
      userId: null,
      message: '',
      submitSuccess: false,
      altarSubStatusToExternalSystemStatusMap,
      nextPageActive: true,
    }
  },
  computed: {
    ...mapGetters('leaders', ['leads', 'leader']),
    headerNamesFiltered() {
      return this.displayExternalId ? headerNames : headerNames.filter(header => header.name !== 'Zewnętrzne ID')
    },
    leadsFiltered() {
      return this.leads.filter(lead => lead.status !== 'deleted')
    },
    currentPage() {
      return Number(this.$route.query.page) || 1
    },
    prevPage(){
      return {
        query: {
          page: this.currentPage - 1
        },
        path: '/dashboard',
      }
    },
    nextPage(){
      return {
        query: {
          page: this.currentPage + 1
        },
        path: '/dashboard',
      }
    },
    displayExternalId() {
      return this.leader.leaderVisibleFields.split(',').includes('externalId')
    }
  },
  watch: {
    async currentPage() {
      await this.getLeads()
    }
  },
  async mounted() {
    if (!await this.hasCredentials()) {
      await this.$router.push('/')
      return
    }
    await this.loadCredentials({})
    await this.getLeads()
  },
  methods: {
    ...mapActions('leaders', ['loadCredentials', 'hasCredentials']),
    async getLeads() {
      const page = this.$route.query.page || 1
      this.nextPageActive = await this.$store.dispatch('leaders/fetchLeaderLeads', {
        providerName: this.leader?.providerName,
        username: this.leader?.login,
        password: this.leader?.password,
        page,
      })
    },
    formatDate(date) {
      return date ? format(new Date(date.toString()), 'yyyy-MM-dd HH:mm') : null
    },
    onSearchChange(e) {
      this.searchContent = e.target.value
    },
    csvSubmitted(data) {
      this.submitSuccess = data.success
      this.message = data.success ? `Wyeksportowano rekordy` : ErrorCodesMessages[data.data]
    },
    onLeadLimitChange() {
      this.getLeads()
    }
  },
}
</script>

<style scoped lang="scss">
.status-panel {
  padding-top: 30px;
  padding-bottom: 60px;
}
.title{
  width: 300px;
  float: left;
}

.color {
  color: #87189D;
}

thead {
  position: sticky;
  top: 60px;
}
th {
  padding: 4px 4px;
  text-align: center;
  background: #33058c;
  color: #f2f2f2;
  //border: 1px solid hsl(0 0% 0% / 1.4);
}

td {
  padding: 4px 4px;
  text-align: center;
  border: 1px solid hsl(0 0% 0% / 0.1);
  white-space: nowrap;
  //max-width: 95px;
  overflow: hidden;
  text-overflow: ellipsis;

  &.no-padding {
    padding: 0;
  }
}

tr {
  &:nth-child(2n) {
    background: hsl(0deg 0% 98%);
  }
  &.is-expanded {

    & td {
      white-space: normal;
      word-break: break-word;
    }

    & ::v-deep .foldable-td {
      white-space: normal;
      word-break: break-word;
    }

    //& .expa
  }
}
table {
  margin: 30px 0 20px 0;
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

button {
  background-color: #87189D;
  color: white;
  border: none;
}

button:hover {
  opacity: 0.8;
  cursor: pointer;
}

.back-link {
  font-size: 16px;
}

.message {
  font-weight: 700;
}

.message-success {
  color: green;
}

.message-error {
  color: red
}

.csv-button {
  margin-bottom: 10px;
}
</style>
