import {FieldTypes} from "../enums/FieldTypes";

export const csvHeaders = [
  {
    name: 'Zewnętrzne ID',
    value: 'externalSourceId',
    type: FieldTypes.STRING,
  },
  {
    name: 'Data utworzenia',
    value: 'createdAt',
    type: FieldTypes.DATE,
  },
  {
    name: 'Status',
    value: 'externalSystemOperationalStatus',
    type: FieldTypes.STRING,
  },
  {
    name: 'Imię',
    value: 'firstName',
    type: FieldTypes.STRING,
  },
  {
    name: 'Nazwisko',
    value: 'lastName',
    type: FieldTypes.STRING,
  },
  {
    name: 'Nazwa',
    value: 'name',
    type: FieldTypes.STRING,
  },
  {
    name: 'Województwo',
    value: 'Province.name',
    type: FieldTypes.REFERENCE,
  },
  {
    name: 'Powiat',
    value: 'Region.name',
    type: FieldTypes.REFERENCE,
  },
  {
    name: 'Kod pocztowy',
    value: 'zipCode',
    type: FieldTypes.STRING,
  },
  {
    name: 'Miejscowość',
    value: 'City.name',
    type: FieldTypes.REFERENCE,
  },
  {
    name: 'Numer telefonu (1)',
    value: 'PhoneNumber.phoneNumber',
    type: FieldTypes.REFERENCE,
  },
  {
    name: 'Numer telefonu (2)',
    value: 'AlternatePhoneNumber.phoneNumber',
    type: FieldTypes.REFERENCE,
  },
  {
    name: 'Ulica',
    value: 'address',
    type: FieldTypes.STRING,
  },
  {
    name: 'Nr budynku',
    value: 'addressStreetNumber',
    type: FieldTypes.STRING,
  },
  {
    name: 'Rachunek',
    value: 'averageBill',
    type: FieldTypes.STRING,
  },
  {
    name: 'Pokrycie dachu',
    value: 'roofCoverage',
    type: FieldTypes.STRING,
  },
  {
    name: 'Nachylenie dachu',
    value: 'roofAngle',
    type: FieldTypes.STRING,
  },
  {
    name: 'Komentarz',
    value: 'comment',
    type: FieldTypes.STRING,
  },
  {
    name: 'Kampania',
    value: 'campaign',
    type: FieldTypes.STRING,
  },
  {
    name: 'ID leadera',
    value: 'leaderId',
    type: FieldTypes.STRING,
  },
]
