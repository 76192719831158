<template>
  <Button @click="exportBlacklistedAsCsv">
    Eksport czarnej listy
  </Button>
</template>

<script>
import {mapActions} from "vuex";

export default {
  methods: {
    ...mapActions('leaders', ['sendBlacklistedPhoneNumbersToCsv']),
    async exportBlacklistedAsCsv() {
      let data = await this.sendBlacklistedPhoneNumbersToCsv()
      this.$emit('csv-submitted', data)
    }
  },
}
</script>

<style scoped>

</style>
