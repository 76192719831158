export const state = () => {
    return {
        leader: null,
        authMissingError: false,
        authInvalidError: false,
        otherError: false,
        leads: [],
        calendarItems: [],
        leadsPerPage: 15,
    }
}
