export const ErrorFieldNames = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  PHONE: 'phoneNumber',
  REGION: 'regionName',
  CITY: 'cityName',
  ZIP_CODE: 'zipCode',
  AVERAGE_BILL: 'averageBill',
  ADDRESS: 'address',
  ADDRESS_STREET_NUMBER: 'addressStreetNumber',
  EXTERNAL_ID: 'externalId',
}
