import Vue from 'vue'
import Vuex from 'vuex'
import App from './components/App.vue'
import modules from './store'
import VueRouter from 'vue-router'
import IndexPage from './pages/Index'
import FormPage from './pages/Form'
import Dashboard from './pages/Dashboard'
import SalesbookCalendar from './pages/SalesbookCalendar'

Vue.use(Vuex)
const store = new Vuex.Store({modules});
Vue.config.productionTip = false

const router = new VueRouter({
  routes: [
    {path: '/', component: IndexPage},
    {path: '/form', component: FormPage, name: 'form'},
    {path: '/dashboard', component: Dashboard, name: 'dashboard'},
    {path: '/salesbook-calendar', component: SalesbookCalendar, name: 'salesbook-calendar'},
  ],
  mode: 'history',
})
Vue.use(VueRouter)

new Vue({
  store,
  render: h => h(App),
  router,
}).$mount('#app')
