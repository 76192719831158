<template>
  <Button @click="exportLeadsAsCsv">
    Pobierz plik .csv
  </Button>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {csvHeaders} from '../data/csvHeaders'

export default {
  props: {
    leads: {
      required: true,
      type: Array,
    },
    displayExternalId: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters('leaders', ['leader']),
    csvHeadersFiltered() {
      return this.displayExternalId ? csvHeaders : csvHeaders.filter(header => header.value !== 'externalSourceId')
    },
    csvHeaderValues() {
      return this.csvHeadersFiltered.map(csvHeader => csvHeader.value)
    },
    headers() {
      return this.csvHeadersFiltered.map(csvHeader => csvHeader.name)
    },
    records() {
      return this.leads.map(lead => {
        const leadCsvValues = []
        for (const csvHeaderValue of this.csvHeaderValues) {
          if (csvHeaderValue.includes('.')) {
            const [objectReference, objectValue] = csvHeaderValue.split('.')
            leadCsvValues.push(lead[objectReference] ? lead[objectReference][objectValue] : null)
          } else {
            leadCsvValues.push(lead[csvHeaderValue])
          }
        }
        return leadCsvValues
      })
    },
  },
  methods: {
    ...mapActions('leaders', ['sendLeadsToCsv']),
    async exportLeadsAsCsv() {
      let data = await this.sendLeadsToCsv({
        headers: this.csvHeadersFiltered,
      })
      if (data && data.success) {
        data = {
          ...data,
          rows: this.records.length
        }
      }
      this.$emit('csv-submitted', data)
    }
  },
}
</script>

<style scoped>

</style>
