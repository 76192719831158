<template>
  <Centered>
    <div class="loading-cover" v-if="isSending"></div>
    <div class="panel-form">
      <div v-if="formSent" style="margin-top: 100px;">
        <Message text="Formularz został wysłany" color="#32a852"/>
      </div>
      <div v-else class="client-form">
        <div class="pane">
          <div class="pane-title"><span class="color">Podstawowe</span> informacje</div>
          <div class="item">
            <div>
              <label for="phone" class="description">
                Numer telefonu
                <span class="color">*</span>
                :
              </label>
            </div>
            <input v-model="fields.phone" type="text" id="phone" placeholder="Wpisz numer telefonu">
            <span v-if="errors.phone" class="error"><span v-for="error in errors.phone" :key="error">{{error}}</span></span>
            <InvalidPhoneMessage
              @phone-number-update="showValidatePhoneNumber"
              :phone="fields.phone.length >= 9 ? formattedPhone : null"
            />
          </div>
          <div class="item">
            <div>
              <label for="firstname" class="description">
                Imię
                <span class="color">*</span>
                :
              </label>
            </div>
            <input v-model="fields.firstName" type="text" id="firstname" placeholder="np. Jan">
            <span v-if="errors.firstName" class="error"><span v-for="error in errors.firstName" :key="error">{{error}}</span></span>
          </div>
          <div class="item" v-if="fieldConfig && fieldConfig.visibleFields.includes('lastName')">
            <div>
              <label for="lastname" class="description">
                Nazwisko
                <span class="color" v-if="fieldConfig && fieldConfig.requiredFields.includes('lastName')">*</span>
                :
              </label>
            </div>
            <input v-model="fields.lastName" type="text" id="lastname" placeholder="np. Kowalski">
            <span v-if="errors.lastName" class="error"><span v-for="error in errors.lastName" :key="error">{{error}}</span></span>
          </div>
          <div class="item" v-if="fieldConfig && fieldConfig.visibleFields.includes('zipCode')">
            <div>
              <label for="zipCode" class="description">
                Kod pocztowy
                <span class="color" v-if="fieldConfig && fieldConfig.requiredFields.includes('zipCode')">*</span>
                :
              </label>
            </div>
            <div>
              <input @input="zipCodeEdited" v-model="formattedZipCode" type="text" id="zipCode" placeholder="XX-XXX" maxlength="6">
              <span
                v-if="showZipcodeError"
                class="zipcode-error"
              >
                Nieprawidłowy kod pocztowy
              </span>
            </div>
            <span v-if="errors.zipCode" class="error"><span v-for="error in errors.zipCode" :key="error">{{error}}</span></span>
          </div>
          <div class="item" v-if="fieldConfig && fieldConfig.visibleFields.includes('province')">
            <label class="description">
              Województwo
              <span class="color" v-if="fieldConfig && fieldConfig.requiredFields.includes('province')">*</span>
              :
            </label>
            <div class="provinces">
              <Radio
                  v-model="fields.province"
                  :id="`province-${i}`"
                  v-for="(province, i) in provinces"
                  :value="province.value"
                  name="province"
                  :key="`province-${i}`"
              >
                {{province.title.toLowerCase()}}
              </Radio>
            </div>
          </div>
          <div class="item" v-if="fieldConfig && fieldConfig.visibleFields.includes('region')">
            <div style="display: flex; align-items: center;">
              <label class="description">
                Powiat
                <span v-if="fieldConfig && fieldConfig.requiredFields.includes('region')" class="color">*</span>
                :
              </label>
              <Searchbar
                ref="regionSelect"
                :text="fields.region"
                :data-list="this.regions"
                :show-add-button="true"
                element-name="powiat"
                @change="updateRegionValue"
                @delay="synchronizeRegions"
              />
            </div>
            <span v-if="errors.region" class="error"><span v-for="error in errors.region" :key="error">{{error }}</span></span>
          </div>

          <div class="item" v-if="fieldConfig && fieldConfig.visibleFields.includes('city')">
            <div style="display: flex; flex-direction: column">
              <label class="description">
                Miejscowość
                <span v-if="fieldConfig && fieldConfig.requiredFields.includes('city')" class="color">*</span>
                :
              </label>
              <Radio
                value="selectCity"
                v-model="cityValueMode"
                @change="cityModeSelect"
              >
                Wybierz z listy
                <select
                  v-if="cityValueMode === 'selectCity'"
                  v-model="selectedCityName"
                >
                  <option value=""> -- </option>
                  <option v-for="(city, i) in cityOptions" :key="i" :value="city">{{city}}</option>
                </select>
              </Radio>
              <div class="city-input-wrapper">
              <Radio
                value="insertCity"
                v-model="cityValueMode"
                @change="cityModeSelect"
              >
                Brak miejscowości na liście?
              </Radio>
              <input
                ref="insertCityInput"
                type="text"
                v-if="cityValueMode === 'insertCity'"
                v-model="cityName"
              >

              </div>
            </div>
            <Radio
              value="emptyCity"
              v-model="cityValueMode"
              @change="cityModeSelect"
            >
              Pozostaw puste
            </Radio>
            <span v-if="errors.city" class="error"><span v-for="error in errors.city" :key="error">{{error}}</span></span>
          </div>
          <div class="address-wrapper">
            <div class="item" v-if="fieldConfig && fieldConfig.visibleFields.includes('address')">
              <label for="address" class="description">
                Ulica
                <span v-if="fieldConfig && fieldConfig.requiredFields.includes('address')" class="color">*</span>
                :
              </label>
              <input v-model="fields.address" type="text" id="address" placeholder="np. ul. Adama Mickiewicza">
              <span v-if="errors.address" class="error"><span v-for="error in errors.address" :key="error">{{error}}</span></span>
            </div>
            <div class="item" v-if="fieldConfig && fieldConfig.visibleFields.includes('addressStreetNumber')">
              <label for="address" class="description">
                Numer budynku
                <span v-if="fieldConfig && fieldConfig.requiredFields.includes('addressStreetNumber')" class="color">*</span>
                :
              </label>
              <input class="house-number-input" v-model="fields.addressStreetNumber" type="text" id="addressStreetNumber" placeholder="np. 14">
              <span v-if="errors.addressStreetNumber" class="error"><span v-for="error in errors.addressStreetNumber" :key="error">{{error}}</span></span>
            </div>
          </div>
          <div class="item" v-if="fieldConfig && fieldConfig.visibleFields.includes('externalId')">
            <div>
              <label for="externalId" class="description">
                ID
                <span class="color" v-if="fieldConfig && fieldConfig.requiredFields.includes('externalId')">*</span>
                :
              </label>
            </div>
            <input v-model="fields.externalId" type="text" id="externalId" placeholder="Wpisz dowolny ciąg znaków">
            <span v-if="errors.externalId" class="error"><span v-for="error in errors.externalId" :key="error">{{error}}</span></span>
          </div>
        </div>
        <div class="pane" v-if="fieldConfig">
          <div class="pane-title"><span class="color">Dodatkowe</span> informacje</div>
          <div class="item" v-for="(question, i) in filteredQuestions" :key="i">
            <div>
              <label class="description">
                {{question.title}}
                <span class="color" v-if="getAdditionalInfoAccess(fieldConfig.requiredFields, question)">*</span>
                :
              </label>
            </div>
            <div v-if="question.isSelect">
              <select v-model="fields[question.name]">
                <option
                  v-for="(answer, j) in question.answers"
                  :key="j"
                  :value="answer.value"
                >{{answer.title}}</option>
              </select>
            </div>
            <div v-else-if="question.isInput">
              <input v-model="fields[question.name]" type="text" id="firstname">
            </div>
            <div v-else class="answers">
              <Radio
                  v-for="(answer, j) in question.answers"
                  v-model="fields[question.name]"
                  :id="`question-${i}-answer-${j}`"
                  :value="answer.value"
                  :name="answer.name"
                  :key="`answer-${j}`"
              >
                {{answer.title}}
              </Radio>
              <div class="question-input" v-if="question.input && fieldConfig.visibleFields.includes(question.input.name)">
                <label class="additional-input description" :for="question.title">
                  {{question.input.title}}
                  <span class="color" v-if="getAdditionalInfoAccess(fieldConfig.requiredFields, question.input)">*</span>
                </label>
                <input type="text" :name="question.title" v-model="fields[question.input.value]" />
              </div>
            </div>
          </div>
          <div class="item">
            <div>
              <label class="description">Dodatkowe informacje</label>
            </div>
            <textarea v-model="fields.additionalComment"></textarea>
          </div>
          <Consents ref="consents" @change="updateConsents"/>
          <div class="summary">
            <button class="button" :disabled="isSending" @click="sendForm">Prześlij formularz</button>
            <Loading v-if="isSending"/>
          </div>
        </div>
        <transition name="error-transition">
          <div v-if="showError" class="big-message fail">{{errorMessage}}</div>
        </transition>
        <transition name="error-transition">
          <div v-if="showSuccess" class="big-message success">{{successMessage}}</div>
        </transition>
      </div>
    </div>
  </Centered>
</template>

<script>

import {mapGetters} from 'vuex'
import Message from "@/components/Message";
import Searchbar from "@/components/Searchbar";
import {ErrorFieldNames} from "@/enums/ErrorFieldNames"
import {ErrorFieldTypes} from "@/enums/ErrorFieldTypes"
import {ErrorFieldCodesMessages} from "@/enums/ErrorFieldCodesMessages";
import Centered from "./common/Centered";
import {provinces} from '../data/provinces';
import Radio from "./common/Radio";
import {questions} from '../data/questions';
import Consents from "@/components/Consents";
import Loading from "@/components/common/Loading";
import InvalidPhoneMessage from "./InvalidPhoneMessage";
import {heatPumpQuestions} from "../data/heatPumpQuestions";

export default {
  components: {
    InvalidPhoneMessage,
    Centered,
    Loading,
    Consents,
    Searchbar,
    Message,
    Radio,
  },
  data() {
    return {
      provinces,
      questions,
      heatPumpQuestions,
      cities: [],
      cityOptions: [],
      cityName: '',
      selectedCityName: '',
      insertedCityName: '',
      cityValueMode: 'emptyCity',
      showZipcodeError: false,
      regions: [],
      errors: {
        phone: '',
        firstName: '',
        lastName: '',
        region: '',
        city: '',
        zipCode: '',
        averageBill: '',
        address: '',
        addressStreetNumber: '',
        externalId: '',
      },
      fields: {
        firstName: '',
        lastName: '',
        region: '',
        city: '',
        province: '',
        houseBuilt: '',
        billsHigher: '',
        averageBill: '',
        roofCoverage: '',
        // correctSurface: '',
        // goodRoof: '',
        // needRepair: '',
        phone: '',
        zipCode: '',
        additionalComment: '',
        // meetingThisWeek: '',
        // subsidies: '',
        address: '',
        addressStreetNumber: '',
        externalId: '',
      },
      errorMessage: null,
      showError: false,
      errorSymbol: null,
      formSent: false,
      successMessage: 'Dziękujemy za przesłanie formularza.',
      showSuccess: false,
      hasConsents: false,
      isSending: false,
      invalidPhoneNumberMessage: '',
    }
  },
  methods: {
    cityModeSelect() {
      if (this.cityValueMode === 'insertCity') {
        this.selectedCityName = ''
        this.cityName = ''
        this.$nextTick(() => this.$refs['insertCityInput'].focus())
        return
      }
      if (this.cityValueMode === 'selectCity') {
        this.cityName = this.selectedCityName
        return
      }
      this.selectedCityName = ''
      this.cityName = ''
    },
    async zipCodeEdited(e) {
      this.cityName = ''
      const zipCode = e.target.value
      if (zipCode.length < 6 || !(/[0-9]{2}-[0-9]{3}/.test(zipCode))) return
      try {
        const addresses = await this.$store.dispatch('leaders/getAddressesByZipcode', {
          zipCode
        })

        this.showZipcodeError = false
        if (!addresses.length) return
        this.cityValueMode = 'selectCity'
        this.$refs.regionSelect.toggleInputInactive(true)
        this.cityOptions = []

        if (addresses.length === 1) {
          this.handleSingleAddress(addresses)
        }
        this.handleMultiAddresses(addresses)
        const {regionName, provinceName} = addresses[0]
        this.fields.province = provinceName
        this.fields.region = regionName
      } catch (e) {
        this.showZipcodeError = true
      }

    },
    handleSingleAddress(addresses) {
      const city = addresses[0].cityName
      this.selectedCityName = city
      this.cityName = city
    },
    handleMultiAddresses(addresses) {
      addresses.map(address => {
        if (!this.cityOptions.includes(address.cityName)) this.cityOptions.push(address.cityName)
      })
    },
      updateConsents(value){
      this.hasConsents = value
    },
    updateRegionValue(value) {
      this.fields.region = value
    },
    async synchronizeRegions() {
      if (this.fields.region.length >= 2) {
        const regions = await this.$store.dispatch('leaders/fetchRegions', {
          region: this.fields.region
        })
        this.regions = regions.map((region) => {
          return {
            id: region.id,
            name: region.name,
          }
        })
      }
    },

    async sendLeadToApi() {
      this.errorMessage = null
      this.showError = false
      this.errorSymbol = null
      this.isSending = true
      try {
        await this.$store.dispatch('leaders/sendForm', {
          lead: {
            firstName: this.fields.firstName,
            lastName: this.fields.lastName,
            region: this.fields.region,
            city: this.cityName,
            phone: this.formattedPhone,
            province: this.fields.province,
            zipCode: this.fields.zipCode,
            averageBill: this.fields.averageBill,
            roofCoverage: this.fields.roofCoverage,
            address: this.fields.address,
            addressStreetNumber: this.fields.addressStreetNumber,
            comment: this.comment,
            externalId: this.fields.externalId === '' ?  null : this.fields.externalId,
          },
        })
        this.clearAllFormValues()
        this.showSuccess = true
        setTimeout(() => {
          this.showSuccess = false
        }, 5000)
      } catch(e) {
        if (e.response.status === 406) {
          const {used, limit} = e.response.data.error;
          this.setErrorMessage(`Osiągnięto limit leadów w tym miesiącu: ${used}/${limit}`, true)
          this.isSending = false
        }
        if (e.response.status !== 422) {
          throw e
        }
        const errors = e.response.data.error
        for (const error of errors) {
          this.errors[error.fieldName] = ''
          const errorMessage = this.generateErrorMessage(error.errors)
          switch (error.fieldName) {
            case ErrorFieldNames.FIRST_NAME:
              this.errors.firstName = errorMessage
              break
            case ErrorFieldNames.LAST_NAME:
              this.errors.lastName = errorMessage
              break
            case ErrorFieldNames.PHONE:
              this.errors.phone = errorMessage
              break
            case ErrorFieldNames.REGION:
              this.errors.region = errorMessage
              break
            case ErrorFieldNames.CITY:
              this.errors.city = errorMessage
              break
            case ErrorFieldNames.ZIP_CODE:
              this.errors.zipCode = errorMessage
              break
            case ErrorFieldNames.AVERAGE_BILL:
              this.errors.averageBill = errorMessage
              break
            case ErrorFieldNames.ADDRESS:
              this.errors.address = errorMessage
              break
            case ErrorFieldNames.ADDRESS_STREET_NUMBER:
              this.errors.addressStreetNumber = errorMessage
              break
            case ErrorFieldNames.EXTERNAL_ID:
              this.errors.externalId = errorMessage
              break
            default:
              if(!this.errorMessage) {
                this.setErrorMessage(errorMessage[0], true)
              }
              break
          }
        }
      }
      this.isSending = false
    },
    async sendForm() {
      for(let key of Object.keys(this.errors)) {
        this.errors[key] = ''
      }
      const fieldValues = [
        'firstName',
        'zipCode',
        'province',
        'region',
        'houseBuilt',
        'billsHigher',
        'roofCoverage',
        'correctSurface',
        'goodRoof',
        'needRepair',
        'phone',
        'averageBill',
        'meetingThisWeek',
        'subsidies',
        'externalId'
      ].filter(field => this.fieldConfig.requiredFields.includes(field)).map(fieldName => this.fields[fieldName])
      const additionalQuestionsRequiredEmpty = () => {
        if (!this.fieldConfig.requiredFields.includes('additionalQuestions')) return false
        const additionalQuestions = ['correctSurface', 'goodRoof', 'needRepair', 'meetingThisWeek', 'subsidies']
        return additionalQuestions.some(question => !this.fields[question])
      }
      if(additionalQuestionsRequiredEmpty() || fieldValues.some(fieldValue => !fieldValue)) {
        return this.setErrorMessage('Wypełnij wszystkie pola', true)
      }
      if(!this.hasConsents) {
        return this.setErrorMessage('Wszystkie potrzebne zgody nie zostały zaznaczone ', true)
      }
      if (this.invalidPhoneNumberMessage) {
        return this.setErrorMessage(this.invalidPhoneNumberMessage, true)
      }
      await this.sendLeadToApi()
    },
    generateErrorMessage(errors) {
      return errors.map((error) => {
        switch(error.type) {
          case ErrorFieldTypes.REQUIRED:
            return 'Wypełnij wszystkie pola.'
          case ErrorFieldTypes.REG_EXP:
            return 'Nieprawidłowy format.'
          case ErrorFieldTypes.MIN_LENGTH:
            return `Min. ${error.params.min} znaków.`
          case ErrorFieldTypes.MAX_LENGTH:
            return `Max. ${error.params.max} znaków.`
          case ErrorFieldTypes.LENGTH_EQUALS:
            return `To pole musi zawierać dokładnie ${error.params.equals} znaków.`
          case ErrorFieldTypes.CUSTOM:
            return ErrorFieldCodesMessages[error.params.code] || 'Wystąpił błąd przy wysyłaniu formularza. Prosimy o kontakt na adres it@sundaypolska.pl z załączonym zrzutem ekranu wypełnionego formularza.'
          default:
            return 'Wystąpił błąd przy wysyłaniu formularza. Prosimy o kontakt na adres it@sundaypolska.pl z załączonym zrzutem ekranu wypełnionego formularza.'
        }
      })
    },
    setErrorMessage(message, shouldHide) {
      this.errorMessage = message
      this.showError = true
      if(!shouldHide) {
        return
      }
      const symbol = Symbol()
      this.errorSymbol = symbol
      setTimeout(() => {
        if(symbol !== this.errorSymbol) {
          return
        }
        this.showError = false
      }, 3000)
    },
    clearAllFormValues() {
      for(let key of Object.keys(this.fields)) {
        this.fields[key] = ''
      }
      this.$refs.consents.clearConsents()
      if (this.fieldConfig.visibleFields.includes(({name}) => name === 'region')) this.$refs.regionSelect.toggleInputInactive(false)
      this.cityValueMode = 'emptyCity'
    },
    showValidatePhoneNumber(message) {
      this.invalidPhoneNumberMessage = message
    },
    getAdditionalInfoAccess(fieldArray, question) {
      const {name} = question
      if (['houseBuilt', 'billsHigher', 'roofCoverage', 'averageBill'].includes(name)) {
        return fieldArray.includes(name)
      } else {
        return fieldArray.includes('additionalQuestions')
      }
    },
    getAdditionalHPInfoAccess(fieldArray, question) {
      const {name} = question
      return fieldArray.includes(name)
    },
  },
  computed: {
    ...mapGetters('leaders', ['leader']),
    fieldConfig() {
      if (!this.leader) return
      return {
        visibleFields: this.leader.leaderVisibleFields.split(','),
        requiredFields: this.leader.leaderRequiredFields.split(','),
      }
    },
    comment() {
      return [
        ...this.filteredQuestions.filter(q => q.name !== 'roofCoverage').map(question => {
          return {
            title: question.title,
            value: this.fields[question.name]
          }
        }),
        {
          title: 'Dodatkowe informacje',
          value: this.fields.additionalComment
        },
      ].map(e => {
        return `${e.title}\n${e.value}`
      }).join('\n\n')
    },
    formattedPhone() {
      return this.fields.phone.match(/\d/g).join('').slice(-9)
    },
    filteredQuestions() {
      if(this.leader.isHP) {
        return this.heatPumpQuestions.filter(question => this.getAdditionalHPInfoAccess(this.fieldConfig.visibleFields, question))
      }
      return this.questions.filter(question => this.getAdditionalInfoAccess(this.fieldConfig.visibleFields, question))
    },
    formattedZipCode: {
      get() {
        return this.fields.zipCode
      },
      set(newValue) {
        const zipCode = newValue.replace(/\D/g, '')
        this.fields.zipCode = [
          [0, 2],
          [2, 5],
        ].filter(e => {
          return zipCode.length >= e[0]
        }).map(e => {
          return zipCode.slice(e[0], e[1])
        }).join('-')
        this.$forceUpdate()
      }
    }
  },
}
</script>

<style scoped lang="scss">

.provinces {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0;
}

.error {
  color: #ff1a1a;
  font-size: 12px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pane {
  background: hsl(0deg 0% 95%);
  padding: 30px;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 10px;
  align-content: flex-start;
  position: relative;
}

.pane-title {
  font-size: 22px;
  margin-bottom: 15px;
}

.item {
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: 8px;
  justify-content: flex-start;
}

.color {
  color: #87189D;
  font-weight: 700;
}

.client-form {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}

.answers {
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
}

.address-wrapper {
  display: flex;
  gap: 30px;
}

label.description {
  font-size: 14px;
  font-weight: 600;
  .color {
    color: #ff1a1a;
  }
}
label.additional-input {
  font-weight: 400;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=text],
input[type=number],
select,
textarea {
  font-size: 13px;
  text-align: left;
  padding: 6px 12px;
  outline: none;
  transition: background .1s ease;
  border: none;
  background: hsl(0deg 0% 90%);
  min-width: 180px;
  max-width: 320px;
  max-height: 140px;
  min-height: 32px;

  &.house-number-input {
    min-width: 40px;
    width: 40px;
  }
}

input[type=text],
input[type=number],
textarea {
  -webkit-appearance: none;
  &:focus {
    background: hsl(0deg 0% 85%);
  }
}

textarea {
  width: 240px;
}

.button {
  border: none;
  padding: 8px 12px;
  font-size: 15px;
  outline: none;
  background: #86179d;
  color: white;
  cursor: pointer;
  //position: absolute;
  //right: 30px;
  //bottom: 30px;
  font-weight: 600;

  &:hover {
    background: white;
    color: #86179d;
  }
}

.big-message {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 15px 15px;
  box-sizing: border-box;
  left: 0;
  color: white;
  text-align: center;
  font-size: 15px;
  z-index: 9999;

  &.fail {
    background: #ff1a1a;
  }

  &.success {
    background: #7b169b;
  }
}

.error-transition-enter-active, .error-transition-leave-active {
  transition: transform .25s ease;
  transform: translateY(0%);
}
.error-transition-enter, .error-transition-leave-to {
  transform: translateY(100%);
}
.summary{
  display: flex;
}
.question-input {
  padding-left: 20px;
}
.loading-cover {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.city-input-wrapper {
  display: flex;
  align-items: center;
}
</style>
