export const altarSubStatusToExternalSystemStatusMap = new Map([
  ['refused', 'Odmowa rozmowy'],
  ['not-answered', 'Nie odbiera'],
  ['disconnected', 'Klient rozłączył się'],
  ['answer-machine', 'Poczta głosowa'],
  ['wrong-number', 'Błędny numer telefonu'],
  ['wrong-personal-details', 'Błąd w danych osobowych'],
  ['arranged', 'Umówione'],
  ['postponed', 'Zmiana terminu'],
  ['resigned', 'Rezygnacja'],
  ['call-later', 'Zadzwoń później'],
  ['duplicate', 'Dubel'],
  ['resigned-not-interested', 'Rezygnacja - nie zainteresowany'],
  ['resigned-unwillingness', 'Rezygnacja - brak chęci rozmowy'],
  ['resigned-roof-less-25m', 'Rezygnacja - dach mniej niż 25m'],
  ['resigned-bill-less-150zl', 'Rezygnacja - rachunek mniej niż 150 zł'],
  ['resigned-house-in-progress', 'Rezygnacja - dom w budowie / nieodebrany'],
  ['resigned-roof-to-fix', 'Rezygnacja - dach do remontu'],
  ['resigned-not-planning-3-months', 'Rezygnacja - nie planuje inwestycji w przeciągu 3 miesięcy' ],
  ['resigned-not-feasible', 'Rezygnacja - brak możliwości montażowych'],
  ['resigned-no-money', 'Rezygnacja - brak środków na inwestycję'],
  ['resigned-no-recording-agreement', 'Rezygnacja - brak zgody na nagrywanie rozmowy'],
  ['resigned-already-mounted', 'Rezygnacja - ma już panele'],
  ['resigned-no-house', 'Rezygnacja - mieszka z bloku / kamienicy'],
  ['resigned-no-owner', 'Rezygnacja - nie jest właścicielem / licznika'],
  ['resigned-no-coowner-agreement', 'Rezygnacja - brak zgody współwłaściciela'],
  ['resigned-disconnected', 'Rezygnacja - rozłączył się'],
  ['resigned-other', 'Rezygnacja - inne'],
  ['blacklisted', 'Czarna lista / Do usunięcia'],
])
