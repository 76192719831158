<template>
  <div class="index">
    <div class="content">
      <h1>Zaloguj się:</h1>
      <input type="text" class="textbox" placeholder="Login" v-model="login" @keyup.enter="navigate">
      <input type="password" class="textbox" placeholder="Password" v-model="password" @keyup.enter="navigate">
      <p v-if="authMissingError" style="color: red;">Uzupełnij wszystkie pola</p>
      <p v-if="authInvalidError" style="color: red;">Niepoprawne dane logowania</p>
      <p v-if="otherError" style="color: red;">Wystąpił nieoczekiwany błąd, skontaktuj się z it@sundaypolska.pl</p>
      <button :disabled="isLoggingIn" @click="navigate" class="button">Przejdź do formularza</button>
      <Loading v-if="isLoggingIn"/>
    </div>
    <Logo/>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import UniversalCookie from "universal-cookie";
import Logo from '../components/Logo'
import Loading from "@/components/common/Loading";


export default {
  data() {
    return {
      isLoggingIn: false,
      login: '',
      password: '',
    }
  },
  computed: {
    ...mapGetters('leaders', ['leader','authMissingError','authInvalidError','otherError']),
  },
  async mounted() {
    await this.loadCredentials({})
    if (await this.hasCredentials()) {
      await this.$router.push('/form')
    }
  },
  methods: {
    ...mapActions('leaders', ['loadCredentials', 'hasCredentials']),
    async navigate() {
      this.isLoggingIn = true
      await this.$store.dispatch('leaders/fetchLeader', {
        login: this.login,
        password: this.password,
      })
      this.isLoggingIn = false
      if(this.leader) {
        const cookies = new UniversalCookie()
        cookies.set('login', this.login)
        cookies.set('password', this.password)
        await this.$router.push({name: 'form'})
      }
    }
  },
  components: {
    Loading,
    Logo,
  }
}
</script>

<style>
#app, body, html {
  height: 100%;
  padding: 0;
  margin: 0;
}
</style>

<style lang="scss" scoped>
.index {
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 100%;
  height: 100%;
}

.content {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

h1 {
  //font-size: 22px;
  font-weight: 500;
  margin-bottom: 8px;
}

.textbox {
  padding: 10px 12px;
  font-size: 16px;
  border: 1px solid hsl(0 0% 0% / 0.3);
  outline: none;
  margin: 12px 0;
  transition: all .1s ease;
  text-align: center;

  &:focus {
    border-color: hsl(0 0% 0% / 0.33);
  }
}

.button {
  color: #86179d;
  font-size: 18px;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  outline: none;
  background: transparent;

  &:hover {
    text-decoration: underline;
  }
}
</style>
