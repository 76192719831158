<template>
  <div class="header">
    <Center>
      <div class="header-wrapper">
        <div class="header-element">
          <div class="logo">
            <img src="/img/sunday-logo.png" alt="Sunday Logo"/>
          </div>
          <div class="title">Formularz <span class="color">Leadów</span></div>
        </div>
        <div class="header-element">
          <router-link
            v-if="leader && leader.canReadRecords"
            class="color"
            to="/dashboard">
            Przekazane leady
          </router-link>
          <router-link
            v-if="leader && leader.canReadSalesbookRecords"
            class="color"
            to="/salesbook-calendar">
            Salesbook calendar
          </router-link>
          <div v-if="leader">Jesteś zalogowany jako <span class="color">{{leader.login}}</span></div>
          <div>
            <LogoutButton />
          </div>
        </div>
      </div>
    </Center>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import Center from './Centered'
  import LogoutButton from "../LogoutButton";

  export default {
    components: {
      LogoutButton,
      Center
    },
    computed: {
      ...mapGetters('leaders', ['leader'])
    }
  }
</script>

<style scoped lang="scss">

  .logo {
    img {
      height: 48px;
    }
  }
  .header {
    background: white;
    position: sticky;
    top: 0;
    z-index: 999;
  }

  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
  }

  .color {
    color: #86179d;
    font-weight: 700;
  }

  .title {
    font-size: 18px;
  }

  .header-element {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 18px;
  }

</style>
