import axios from 'axios'
import UniversalCookie from 'universal-cookie'
import {KnownError} from "@/enums/KnownError";

export const actions = {
  async fetchLeader({commit}, {login, password}){
    try {
      commit('setAuthMissingError', false)
      commit('setAuthInvalidError', false)
      commit('setOtherError', false)
      const res = await axios.post(process.env.VUE_APP_API_HOST + '/leaders/login', {
        login,
        password,
      })
      const leader = res.data.data.leader
      commit('setLeader', leader)
    }catch (e) {
      const code = e?.response?.status || 500
      if(code >= 400 && code <500) {
        switch(e.response.data.error.name){
          case KnownError.MISSING_CREDENTIALS:
            commit('setAuthMissingError', true)
            break
          case KnownError.INVALID_CREDENTIALS:
            commit('setAuthInvalidError', true)
            break
        }
      }
      else if(code >= 500)
        commit('setOtherError', true)
    }
  },

  async loadCredentials({commit}, {request}) {
    const cookies = new UniversalCookie(request?.headers?.cookie)
    const login = cookies.get('login')
    const password = cookies.get('password')
    if(login && password) {
      const res = await axios.post(process.env.VUE_APP_API_HOST + '/leaders/login', {
        login,
        password,
      })
      const leader = res.data.data.leader
      commit('setLeader', leader)
    }
  },

  hasCredentials() {
    const cookies = new UniversalCookie()
    const login = cookies.get('login')
    const password = cookies.get('password')
    return !!login && !!password
  },

  async fetchCities(context, {city}){
    const res = await axios(process.env.VUE_APP_API_HOST + '/cities/search', {
      params:{
        query: city,
        isVerified: true,
      }
    })
    return res.data.data.cities
  },

  async fetchRegions(context, {region}){
    const res = await axios(process.env.VUE_APP_API_HOST + '/regions/search', {
      params:{
        query: region,
        isVerified: true,
      }
    })
    return res.data.data.regions
  },

  async getAddressesByZipcode(context, {zipCode}){
    const res = await axios.get(`${process.env.VUE_APP_API_HOST}/zip-codes/${zipCode}`)
    return res.data.data.locations
  },

  async sendForm({rootGetters}, {lead}){
    const leader = rootGetters['leaders/leader']
    const {login, password, providerName} = leader
    const {firstName, lastName, region, province, city, phone, comment, zipCode, averageBill, roofCoverage, address, addressStreetNumber, externalId} = lead
    return axios({
      method: 'post',
      url: `${process.env.VUE_APP_API_HOST}/leads`,
      data: {
        firstName,
        lastName,
        regionName: region,
        provinceName: province,
        cityName: city,
        phoneNumber: phone,
        comment,
        zipCode,
        averageBill,
        roofCoverage,
        address,
        addressStreetNumber,
        sourceMethod: 'leader',
        externalId,
        providerName,
      },
      auth: {
        password,
        username: login,
      }
    })
  },

  async fetchLeaderLeads({commit, rootGetters}, {providerName, username, password, page}){
    const limit = rootGetters['leaders/leadsPerPage']
    const res = await axios({
      method: 'get',
      url: `${process.env.VUE_APP_API_HOST}/provider/${providerName}/leads`,
      auth: {
        password,
        username,
      },
      params: {
        limit: limit + 1,
        page,
      },
    })
    const leads = res.data.data.leads
    const showNextPage = leads.length === limit + 1
    if (showNextPage) leads.pop()
    commit('setLeads', leads)
    return !!showNextPage
  },

  async fetchLeaderSalesbookCalendarItems({commit}, {username, password, prefix}){
    const res = await axios({
      method: 'get',
      url: `${process.env.VUE_APP_API_HOST}/salesbook-calendar/${prefix}`,
      auth: {
        password,
        username,
      },
    })
    const calendarItems = res.data.data.calendarEntries
    commit('setCalendarItems', calendarItems)
    return calendarItems
  },

  async sendLeadsToCsv({rootGetters}, {headers}){
    const leader = rootGetters['leaders/leader']
    const {providerName, login, password} = leader
    try {
      const res = await axios({
        method: 'post',
        url:`${process.env.VUE_APP_API_HOST}/provider/${providerName}/leads`,
        auth: {
          password,
          username: login,
        },
        data: {
          headers,
        },
      })
      const d = new Date()
      const fileName =
        `moje_umowy-${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}-${d.getHours()}${d.getMinutes()}${d.getSeconds()}.csv`
      let csvResult = await res.data.data.csvResult
      csvResult = `\ufeff` + csvResult

      const blob = new Blob([csvResult], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      return {
        success: true,
        data: csvResult,
      }
    } catch (e) {
      if (e.response?.data) return {
        success: false,
        data: e.response.data.error,
      }
    }
  },

  async sendBlacklistedPhoneNumbersToCsv({rootGetters}){
    const leader = rootGetters['leaders/leader']
    const {login, password} = leader
    try {
      const res = await axios({
        method: 'post',
        url:`${process.env.VUE_APP_API_HOST}/phone-numbers/blacklist/download`,
        auth: {
          password,
          username: login,
        },
      })
      const d = new Date()
      const fileName =
        `czarna_lista-${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}-${d.getHours()}${d.getMinutes()}${d.getSeconds()}.csv`
      let csvResult = await res.data.data.csvResult
      csvResult = `\ufeff` + csvResult

      const blob = new Blob([csvResult], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      return {
        success: true,
        data: csvResult,
      }
    } catch (e) {
      if (e.response?.data) return {
        success: false,
        data: e.response.data.error,
      }
    }
  },

  async exportSalesbookCalendarItemsToCsv({rootGetters}, {headers, records}){
    const leader = rootGetters['leaders/leader']
    const {login, password} = leader
    try {
      const res = await axios({
        method: 'post',
        url:`${process.env.VUE_APP_API_HOST}/export-csv`,
        auth: {
          password,
          username: login,
        },
        data: {
          headers,
          records,
        },
      })
      const d = new Date()
      const fileName =
        `salesbook-calendar-${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}-${d.getHours()}${d.getMinutes()}${d.getSeconds()}.csv`
      let csvResult = await res.data.data.csvFileContent
      csvResult = `\ufeff` + csvResult

      const blob = new Blob([csvResult], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      return {
        success: true,
        data: csvResult,
      }
    } catch (e) {
      if (e.response?.data) return {
        success: false,
        data: e.response.data.error,
      }
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async checkLeadDuplicates({}, {phoneNumber}){
    const res = await axios({
      method: 'post',
      url: `${process.env.VUE_APP_API_HOST}/leads/duplicate-exists`,
      data: {
        phoneNumber,
        daysCount: 180,
      },
    })
    return res.data.data
  },

  async checkNumberBlacklisted({rootGetters}, {phoneNumber}){
    const leader = rootGetters['leaders/leader']
    const {login, password} = leader
    const res = await axios({
      method: 'post',
      url: `${process.env.VUE_APP_API_HOST}/phone-numbers/blacklist/check`,
      data: {
        phoneNumber,
      },
      auth: {
        password,
        username: login,
      },
    })
    return res.data.data
  },
}
