export const questions = [
    {
        title: 'Czy dom jest oddany do użytku?',
        name: 'houseBuilt',
        answers: [
            {
                title: 'Tak',
                value: 'Tak'
            },
            {
                title: 'Nie',
                value: 'Nie'
            }
        ]
    },
    {
        title: 'Rachunki za prąd powyżej 125 zł?',
        name: 'billsHigher',
        answers: [
            {
                title: 'Tak',
                value: 'Tak'
            },
            {
                title: 'Nie',
                value: 'Nie'
            }
        ],
        input: {
            title: 'Jaka kwota: ',
            name: 'averageBill',
            value: 'averageBill'
        }
    },
    {
        title: 'Czym pokryty jest dach?',
        name: 'roofCoverage',
        isSelect: true,
        answers: [
            {
                title: 'blacha',
                value: 'blacha'
            },
            {
                title: 'blachodachówka',
                value: 'blachodachówka'
            },
            {
                title: 'blacha trapezowa',
                value: 'blacha trapezowa'
            },
            {
                title: 'dachówka ceramiczna',
                value: 'dachówka ceramiczna'
            },
            {
                title: 'papa',
                value: 'papa'
            },
            {
                title: 'gont bitumiczny',
                value: 'gont bitumiczny'
            },
            {
                title: 'grunt',
                value: 'grunt'
            },
            {
                title: 'inne',
                value: 'inne'
            },
        ]
    },
    {
        title: 'Minimum 25m2 nasłonecznionej  powierzchni dachu?',
        name: 'correctSurface',
        answers: [
            {
                title: 'Tak',
                value: 'Tak'
            },
            {
                title: 'Nie',
                value: 'Nie'
            }
        ]
    },
    {
        title: 'Czy dach w dobrym stanie?',
        name: 'goodRoof',
        answers: [
            {
                title: 'Tak',
                value: 'Tak'
            },
            {
                title: 'Nie',
                value: 'Nie'
            }
        ]
    },
    {
        title: 'Czy wymagana modernizacja?',
        name: 'needRepair',
        answers: [
            {
                title: 'Tak',
                value: 'Tak'
            },
            {
                title: 'Nie',
                value: 'Nie'
            }
        ]
    },
    {
        title: 'Czy Klient gotowy na spotkanie w ciągu 7 dni?',
        name: 'meetingThisWeek',
        answers: [
            {
                title: 'Tak',
                value: 'Tak'
            },
            {
                title: 'Nie',
                value: 'Nie'
            }
        ]
    },
    {
        title: 'Czy klient zainteresowany dostępnymi dofinansowaniami/dotacjami?',
        name: 'subsidies',
        answers: [
            {
                title: 'Tak',
                value: 'Tak'
            },
            {
                title: 'Nie',
                value: 'Nie'
            }
        ]
    },
]
