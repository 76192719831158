<template>
  <div id="app">
    <RouterView/>
  </div>
</template>

<script>
  export default {
    name: 'App',
  }
</script>

<style lang="scss">
  @import url('https://pro.fontawesome.com/releases/v5.15.3/css/solid.css');
  @import url('https://pro.fontawesome.com/releases/v5.15.3/css/regular.css');
  @import url('https://pro.fontawesome.com/releases/v5.15.3/css/fontawesome.css');
  @import url('https://pro.fontawesome.com/releases/v5.15.3/css/brands.css');
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

  body, input, button, select, textarea, h1, h2 {
    font-family: 'Poppins', sans-serif;
    letter-spacing: -0.2px;
    color: hsla(0, 0%, 10%, 1);
  }

  body, input, button, select, textarea {
    font-size: 13px;
  }
</style>
