export const SalesbookCalendarFields = [
  {
    name: 'Status spotkania',
    value: 'status',
    type: 'string',
    width: '80px'
  },
  {
    name: 'Data utworzenia spotkania',
    value: 'createdAt',
    type: 'date',
    width: '120px'
  },
  {
    name: 'Data rozpoczęcia spotkania',
    value: 'startDate',
    type: 'date',
    width: '120px'
  },
  {
    name: 'Twórca spotkania',
    value: 'user',
    type: 'string',
    width: '120px'
  },
  {
    name: 'Imię klienta',
    value: 'name',
    type: 'string',
    width: '80px'
  },
  {
    name: 'Nazwisko klienta',
    value: 'lastName',
    type: 'string',
    width: '80px'
  },
  {
    name: 'Numer telefonu',
    value: 'phone',
    type: 'string',
    width: '100px'
  },
  {
    name: 'Województwo',
    value: 'district',
    type: 'string',
    width: '100px'
  },
  {
    name: 'Powiat',
    value: 'region',
    type: 'string',
    width: '90px'
  },
  {
    name: 'Kod pocztowy',
    value: 'postCode',
    type: 'string',
    width: '60px'
  },
  {
    name: 'Miasto',
    value: 'city',
    type: 'string',
    width: '90px'
  },
  {
    name: 'Ulica',
    value: 'address',
    type: 'string',
    width: '90px'
  },
  {
    name: 'Numer domu',
    value: 'houseNumber',
    type: 'string',
    width: '50px'
  },
]
